// Core
import { useTranslation } from "react-i18next";

// Components
import { Title } from "@/client/components/ui/Typography/Title";
import { Link } from "@/client/components/others/link";

// Definitions
import { type MenuData } from "@/client/definitions/footer";

// Styles
import st from "./styles.module.css";

type FooterNavMenuType = { data: MenuData; testId?: string };
export const FooterNavMenu = ({ data, testId = "footer-navigation-menu" }: FooterNavMenuType) => {
  const { t } = useTranslation("common");

  return (
    <div className={st["footer-nav"]} data-testid={testId}>
      <Title tag="h6" size="16" color="gray-200">
        {t(data.heading)}
      </Title>
      <div className={st["footer-nav__items"]}>
        {data.items.map(({ anchor, href }) => {
          return (
            anchor && (
              <Link href={href} size="14" color="gray-600" box="block" key={anchor}>
                {t(anchor)}
              </Link>
            )
          );
        })}
      </div>
    </div>
  );
};
